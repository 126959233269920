























































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Popup from '@/components/Popup.vue';
import { Auth } from 'aws-amplify';
import { State } from 'vuex-class';
import _ from 'lodash';
import { updateUserInfo } from '@/services/api';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

@Component({
    components: { Popup },
})
export default class Signin extends Vue {
    @State
    userInfo;

    authState = null;

    get userEmail() {
        return _.get(this.userInfo, ['attributes', 'email']);
    }


    async mounted() {
        onAuthUIStateChange((nextAuthState, data) => {
            this.authState = nextAuthState;
            console.log('onAuthStateChange', nextAuthState, data);
            return updateUserInfo();
        });
        await updateUserInfo();
    }
}
