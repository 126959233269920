var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"sign-out"},[(_vm.$route.name=='Home')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white","icon":"","large":""},on:{"click":_vm.showHelp}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help")])],1)]}}],null,false,2120272036)},[_c('span',[_vm._v("How to play")])]):_vm._e(),(_vm.userInfo != null && _vm.$route.name=='Home')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white","large":""},on:{"click":_vm.signOut}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}],null,false,4141154576)},[_c('span',[_vm._v("Log out")])]):_vm._e()],1),_c('div',{staticClass:"biominga-site"},[(_vm.$route.name=='Home')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white","icon":"","large":""},on:{"click":_vm.openBiominga}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}],null,false,2544792820)},[_c('span',[_vm._v("Go to biominga.com")])]):_vm._e()],1),_c('div',{staticClass:"home-text",class:{ 'hidden-text': _vm.completed }},[_c('img',{staticStyle:{"max-width":"50%","margin-top":"20px"},attrs:{"src":require('../assets/home/title.png')}})]),_c('div',{staticClass:"wheel-div",class:{ 'zoom-in': _vm.completed }},[_c('wheels',{on:{"spinCompleted":_vm.spinCompleted}})],1),_c('div',{staticClass:"home-text",class:{ 'hidden-text': _vm.completed }},[_c('img',{staticStyle:{"max-width":"80%","margin-bottom":"20px"},attrs:{"src":require('../assets/home/subtitle.png')}})]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }