

































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Popup from '@/components/Popup.vue';
import { authApi } from '@/services/api';
import { Auth } from 'aws-amplify';
import { State, Getter } from 'vuex-class';
import _ from 'lodash';
import dayjs from 'dayjs';

declare global {
    interface Window {
        Stripe: any;
    }
}

@Component({
    components: { Popup },
})
export default class Subscribe extends Vue {
    @State
    userInfo;
    @Getter
    jwtToken;

    agreed = false;
    subscriptions = [];

    get subscription() {
        return this.subscriptions[0];
    }
    get otherSubscriptions() {
        return this.subscriptions.slice(1);
    }
    get subscribed() {
        return (
            this.subscriptions &&
            this.subscriptions.length > 0 &&
            this.subscriptions.some((s) => s.status == 'active')
        );
    }
    openLink(link) {
        location.href = link;
    }
    async mounted() {
        this.subscriptions = await authApi('get-subscriptions', 'GET');
    }

    toDate(ts) {
        return dayjs(ts * 1000).toString();
    }
    _stripe = null;
    get stripe() {
        if (!this._stripe)
            this._stripe = new window.Stripe(
                'pk_live_51HqKjoCJHPVwwJg4QkKsmzjcs74kY1YWCrZcrvqvCfq2rhoBvAqCKrJ01ziQiU4cNJD5WO77zPpRD4uUfPvDsiX800up31eKWq'
            );
        return this._stripe;
    }
    async stripeCheckoutSession() {
        console.log('location', location.toString(), location);
        const result = await authApi('stripe-create-checkout-session', 'POST', {
            priceId: 'price_1I7D3aCJHPVwwJg4oEpmanRX',
            location: location,
            email: this.userInfo.email,
            cancel_url: location.origin + '/cards',
        });
        const sessionId = await this.stripe.redirectToCheckout({
            sessionId: result.sessionId,
        });
        console.log('sessionId:', sessionId);
    }
    async cancelSubscription() {
        const result = await authApi('delete-subscription', 'DELETE');
        console.log({ result });
        if (result.every((s) => s.status === 'canceled'))
            window.alert(
                'You can continue using your subscription until the expiry time. It will not be automatically renewed.'
            );
        this.subscriptions = await authApi('get-subscriptions', 'GET');
    }
}
