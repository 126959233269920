export function mod(n, m) {
    return ((n % m) + m) % m;
}

export class CircularBuffer<T> {
    buffer: T[] = [];
    size: number;
    index = 0;
    constructor(size: number) {
        this.size = size;
    }

    append(v: T) {
        this.buffer[this.index++] = v;
        this.index = mod(this.index, this.size);
    }
    getLast(index = 0) {
        const i = mod(this.index - index - 1, this.size);
        return this.buffer[i];
    }
    getOldest() {
        const firstPass = this.index == this.buffer.length;
        return firstPass ? this.buffer[0] : this.buffer[this.index];
    }
    mapBackward<U>(f: (T) => U) {
        const firstPass = this.index == this.buffer.length;
        if (firstPass) return this.buffer.reverse().map(f);
        else
            return [
                ...this.buffer
                    .slice(0, this.index)
                    .reverse()
                    .map(f),
                ...this.buffer
                    .slice(this.index, this.size)
                    .reverse()
                    .map(f),
            ];
    }
}
