import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import Signin from '@/components/Signin.vue';
import Cards from '@/components/Cards.vue';
import Subscribe from '@/components/Subscribe.vue';
import SubscribeSuccess from '@/components/SubscribeSuccess.vue';
import SubscribeCancel from '@/components/SubscribeCancel.vue';
import CardFullscreen from '@/components/CardFullscreen.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        children: [
            {
                path: 'signin',
                name: 'signin',
                component: Signin,
            },
            {
                path: 'cards',
                name: 'cards',
                component: Cards,
                children: [
                    {
                        path: 'card',
                        name: 'cardFullscreen',
                        component: CardFullscreen,
                    },
                    {
                        path: 'subscribe',
                        name: 'subscribe',
                        component: Subscribe,
                    },
                ],
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
