











































































































import { Component, Vue } from 'vue-property-decorator';
import Wheels from '@/components/Wheels.vue';
import Cards from '@/components/Cards.vue';
import { Getter, State, Mutation } from 'vuex-class';
import { Auth } from 'aws-amplify';
import { updateUserInfo, jwtToken } from '@/services/api';

@Component({
    components: {
        Wheels,
        Cards,
    },
})
export default class Home extends Vue {
    completed = false;
    showCards = false;
    @State
    userInfo;
    async mounted() {
        console.log('home, route:', this.$route);
        this.$vuetify.theme.dark = false;
        if (!(await Auth.currentUserInfo())) {
            setTimeout(() => this.pushView('signin'), 200);
            return;
        }
        await updateUserInfo();
        const oldResult = JSON.parse(localStorage.getItem('spinResult'));
        if (oldResult) this.spinCompleted(oldResult);
    }
    openBiominga() {
        location.href = 'https://biominga.com';
    }

    pushView(name) {
        if (this.$route.name != name) this.$router.push({ name });
    }
    showHelp() {
        this.pushView('signin');
    }
    async signOut() {
        await Auth.signOut();
        this.pushView('signin');
    }
    spinCompleted(result) {
        console.log('home - spin completed: ', result);
        this.completed = true;
        localStorage.setItem('spinResult', JSON.stringify(result));
        if (this.$route.name == 'Home') setTimeout(() => this.pushView('cards'), 5000);
    }
}
