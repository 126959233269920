










































import { Component, Vue, Prop } from 'vue-property-decorator';
import { authApi, ResponseType } from '@/services/api';
import { State } from 'vuex-class';
import Card from '@/components/Card.vue';
import { CardDef } from '@/models/card';
import VueFlip from 'vue-flip';
type CardsDefMap = { [color: string]: CardDef };
@Component({
    components: { 'vue-flip': VueFlip, Card },
})
export default class Cards extends Vue {
    result = null;
    colors = ['green', 'orange', 'yellow'];
    // use this to dowload images: https://stackoverflow.com/questions/60844783/vue-js-show-image-dynamically-after-downloading-it

    cards: CardsDefMap = {
        green: {
            url: null,
            backUrl: null,
            startingPosition: 'top: 5%; left: 30%;',
            flipped: false,
            cssClass: 'green',
        },
        orange: {
            url: null,
            backUrl: null,
            startingPosition: 'top: 40%; left: 67%;',
            flipped: false,
            cssClass: 'orange',
        },
        yellow: {
            url: null,
            backUrl: null,
            startingPosition: 'top: 50%; left: 15%;',
            flipped: false,
            cssClass: 'yellow',
        },
    };
    @State
    subscription;

    async fetchCards() {
        for (const [i, color] of this.colors.entries()) {
            this.cards[color].url = window.URL.createObjectURL(
                new Blob([
                    await authApi(
                        `card?color=${color}&i=${this.result[i]['sector']}`,
                        'GET',
                        undefined,
                        ResponseType.blob
                    ),
                ])
            );
        }
    }
    async fetchCardBacks() {
        for (const [i, color] of this.colors.entries()) {
            this.cards[color].backUrl = window.URL.createObjectURL(
                new Blob([
                    await authApi(
                        `card-back?color=${color}&i=${this.result[i]['sector']}`,
                        'GET',
                        undefined,
                        ResponseType.blob
                    ),
                ])
            );
        }
    }

    async mounted() {
        this.result = JSON.parse(localStorage.getItem('spinResult'));

        await this.fetchCards();

        const stripeSession = this.$route.query['session_id'];
        console.log('stripe session:', stripeSession);
        const subscriptions = await authApi('get-subscriptions', 'GET');
        console.log('subscriptions', subscriptions);
        if (subscriptions && subscriptions.length) {
            this.$store.commit('setSubscription', subscriptions[0]);
            await this.fetchCardBacks();
        }
    }


    goBack() {
        localStorage.removeItem('spinResult');
        this.$router.replace('/');
        location.reload();
    }
}
