












import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import _ from 'lodash';

@Component({
    components: {},
})
export default class CardFullscreen extends Vue {
    @State
    card;

    mounted() {
        if (!this.card) this.$router.back();
        console.log('CardFullscreen mounted');
    }
}
