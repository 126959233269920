

























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WheelDef, Size } from '@/models/geometry';
import { CircularBuffer, mod } from '@/services/circularBuffer';
import * as _ from 'lodash';
@Component({
    components: {},
})
export default class Wheels extends Vue {
    @Prop()
    wheel: WheelDef;

    @Prop()
    enabled: boolean;

    @Prop()
    scale: number;

    @Prop()
    highRes: boolean;

    previousRotation = 0;
    initialAngularPosition = 0;
    rotation = 0;
    bindMouse = false;
    spinning = false;

    staticNaturalSize: Size = { width: 0, height: 0 };
    wheelNaturalSize: Size = { width: 0, height: 0 };
    mounted() {
        this.$refs.static['onload'] = () => {
            this.staticNaturalSize = {
                width: this.$refs.static['naturalWidth'],
                height: this.$refs.static['naturalHeight'],
            };
        };
        this.$refs.wheel['onload'] = () => {
            if (this.highRes) return;
            this.wheelNaturalSize = {
                width: this.$refs.wheel['naturalWidth'],
                height: this.$refs.wheel['naturalHeight'],
            };
        };
    }
    get styleStatic() {
        return `top:${this.scale * (this.wheel.pos.y - this.wheel.center.y +this.wheel.staticOffset.y)}px;
        left:${this.scale * (this.wheel.pos.x - this.wheel.center.x+this.wheel.staticOffset.x)}px;
        width:${this.scale * this.staticNaturalSize.width}px;`;
    }
    get style() {
        const style = `
        top:${this.scale * (this.wheel.pos.y - this.wheel.center.y)}px;
        left:${this.scale * (this.wheel.pos.x - this.wheel.center.x)}px;
        -moz-transform: rotate(${this.rotation}deg);
        -webkit-transform: rotate(${this.rotation}deg);
        -o-transform: rotate(${this.rotation}deg);
        -ms-transform: rotate(${this.rotation}deg);
        transform: rotate(${this.rotation}deg);

        width:${this.scale * this.wheelNaturalSize.width}px;
        height:${this.scale * this.wheelNaturalSize.height}px;`;

        return style;
    }
    positions: CircularBuffer<{ r: number; t: number; x: number; y: number }> = null;
    angularPosition(e: MouseEvent): number {
        const relX = e.pageX - this.wheel.pos.x;
        const relY = e.pageY - this.wheel.pos.y;
        const rotRadians = Math.atan2(relX, relY);
        const angPos = (rotRadians * -180) / Math.PI + 90;
        if (Date.now().valueOf() - this.lastlog > 500) {
            this.lastlog = Date.now().valueOf();
            console.log(
                'relX:',
                relX,
                'relY:',
                relY,
                'rotRadians:',
                rotRadians,
                'angPos:',
                angPos
            );
        }

        return angPos;
    }
    mouseDown(e: MouseEvent) {
        e.preventDefault();
        if (!this.enabled) return;
        this.bindMouse = true;
        this.previousRotation = this.rotation;
        this.initialAngularPosition = this.angularPosition(e);
        document.addEventListener('mouseup', () => {
            this.startSpinning();
        });
        this.positions = new CircularBuffer(250);
    }
    vel() {
        const old = this.positions.getOldest();
        if (!old) return 0;
        // const degDiff = mod(this.positions.getLast(0).r - old.r, 360);
        const last = this.positions.getLast(0);
        const dx = last.x - old.x;
        const dy = last.y - old.y;
        const distance = Math.sqrt(dx ** 2 + dy ** 2);
        const dt = (Date.now() - old.t) / 1000;
        // console.log(old, last, distance, dt);
        let sign = this.rotation > this.previousRotation ? +1 : -1;

        const pos = this.wheel.pos;
        const quadrants = this.positions
            .mapBackward((p) => {
                if (p.x >= pos.x && p.y >= pos.y) return 1;
                else if (p.x >= pos.x && p.y < pos.y) return 0;
                else if (p.x < pos.x && p.y >= pos.y) return 2;
                else if (p.x < pos.x && p.y < pos.y) return 3;
            })
            .reduce((a, e) => (e == a[a.length - 1] ? a : [...a, e]), [])
            .reverse();
        // console.log(quadrants);
        if (_.isEqual(quadrants, [0, 3])) sign = -1;
        if (_.isEqual(quadrants, [3, 0])) sign = 1;
        return (sign * distance) / dt;
    }
    lastlog = 0;
    followMouse(e: MouseEvent) {
        if (!this.bindMouse || !this.enabled) return;
        const currentAngularPosition = this.angularPosition(e);
        const delta = currentAngularPosition - this.initialAngularPosition;
        this.rotation = this.previousRotation + delta;
        this.positions.append({
            t: Date.now(),
            r: this.rotation,
            x: e.pageX,
            y: e.pageY,
        });
    }
    timer;
    velocity: number;
    k = 0.1;
    stopping = false;
    get selectedSector() {
        return Math.floor(
            mod(this.rotation + this.wheel.finalOffset, 360) /
                (360 / this.wheel.divisions)
        );
    }
    startSpinning() {
        if (!this.bindMouse || !this.enabled) return;
        this.$emit('startSpinning');
        this.bindMouse = false;
        this.spinning = true;
        this.velocity = this.vel();
        this.timer = setInterval(() => {
            this.rotation += this.velocity * this.k;
            this.velocity *= 0.9;
            if (Math.abs(this.velocity) < 90) {
                this.stopping = true;
            }

            if (this.stopping) {
                // console.log(
                //     mod(
                //         this.rotation + this.wheel.finalOffset,
                //         360 / this.wheel.divisions
                //     )
                // );
            }
            if (
                this.stopping &&
                (mod(this.rotation + this.wheel.finalOffset, 360 / this.wheel.divisions) <
                    5 ||
                    Math.abs(this.velocity) < 1)
            ) {
                clearInterval(this.timer);
                this.spinning = false;
                console.log('stop spinning:', this.selectedSector, this.timer);
                this.$emit('stopSpinning', this.selectedSector);
            }
        }, 100);
    }
}
