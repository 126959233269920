/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b7c7a736-ff35-4c68-a13c-7f57ebaace8c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_nPDwITFxf",
    "aws_user_pools_web_client_id": "2jbkbnjv31a10atn72q1qtrac0",
    "oauth": {}
};


export default awsmobile;
