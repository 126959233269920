import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue';
import aws_exports from './aws-exports';
import VueAuthImage from 'vue-auth-image';
import VueMobileDetection from 'vue-mobile-detection';
Vue.use(VueAuthImage);
Vue.use(VueMobileDetection);

Amplify.configure(aws_exports);
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    //eslint-disable-next-line
    vuetify,
    //eslint-disable-next-line
    render: (h) => h(App),
}).$mount('#app');
