import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Framework } from 'vuetify';

Vue.use(Vuetify);

declare module 'vue/types/vue' {
    interface Vue {
        $vuetify: Framework;
    }
}

export default new Vuetify({
    theme: { dark: false },
});
