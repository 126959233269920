

















import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import VueFlip from 'vue-flip';
import _ from 'lodash';
import { CardDef } from '@/models/card';

@Component({
    components: { VueFlip },
})
export default class Card extends Vue {
    @Prop()
    value: CardDef;
    @State
    subscription;

    mounted() {
        console.log('Card mounted');
    }
    isChrome(){
        return !!navigator.userAgent.match(/Chrome/);
    }
    flip() {
        if (!this.value.flipped && !this.subscription) {
            this.$router.push({ name: 'subscribe' });
        } else {
            // if (this.isChrome())
            // {
            //     this.value.flipped = !this.value.flipped;
            // }
            // else
            // {
                this.$store.commit('setCurrentCard',this.value);
                this.$router.push({name:'cardFullscreen'});
            // }
        }
    }
}
