









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class Popup extends Vue {
    @Prop()
    prop;

    mounted() {
        console.log('Popup mounted');
    }
}
