










































import Vue from 'vue';
import Component from 'vue-class-component';
import Wheel from './Wheel.vue';
import { WheelDef } from '@/models/geometry';
import {State} from 'vuex-class';

@Component({
    components: { Wheel },
})
export default class Wheels extends Vue {
    wheels: WheelDef[] = [
        {
            file: 'home/green-top.png',
            fullRes: 'home/green-top-fullres.png',
            staticFile: 'home/green-flapper.png',
            center: { x: 186, y: 186 },
            pos: { x: 417, y: 208 },
            staticOffset: {x:76,y:170},
            size: { width: 372, height: 372 },
            divisions: 8,
            finalOffset: 3,
        },
        {
            file: 'home/orange-right.png',
            fullRes: 'home/orange-right-fullres.png',
            staticFile: 'home/orange-flapper.png',
            center: { x: 186, y: 186 },
            pos: { x: 536, y: 421 },
            staticOffset: {x:173,y:170},
            size: { width: 373, height: 372 },
            divisions: 6,
            finalOffset: 5,
        },
        {
            file: 'home/yellow-left.png',
            fullRes: 'home/yellow-left-fullres.png',
            staticFile: 'home/yellow-flapper.png',
            center: { x: 186, y: 186 },
            pos: { x: 300, y: 421 },
            staticOffset: {x:68,y:170},
            size: { width: 372, height: 372 },
            divisions: 6,
            finalOffset: 4,
        },
    ];
    @State
    userInfo;
    enableSpinning = true;
    completed = false;

    finalTimer;
    startSpinning(w: WheelDef) {
        w.spinning = true;
        clearTimeout(this.finalTimer);
    }
    stopSpinning(w: WheelDef, sector: number) {
        w.spun = true;
        w.spinning = false;
        w.selectedSector = sector;
        if (this.wheels.every((w) => w.spun))
            this.finalTimer = setTimeout(this.allWheelsSpun.bind(this), 3500);
    }

    allWheelsSpun() {
        this.enableSpinning = false;
        const spinResult = this.wheels.map((w: WheelDef) => ({
            name: w.file,
            sector: w.selectedSector,
        }));

        this.completed = true;
        this.$emit('spinCompleted', spinResult);
        console.log('spin result:', spinResult);
    }

    mounted() {
        window.addEventListener('resize', this.onResize);
        this.$refs.base['onload'] = this.onResize.bind(this);
    }
    updated() {
        this.onResize();
    }
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    }
    scale = 1.0;
    onResize() {
        // we leave the base to resize automatically, and then apply the same scale to other images
        this.scale = this.$refs.base['width'] / this.$refs.base['naturalWidth'];
    }
}
