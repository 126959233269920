import Vue from 'vue';
import Vuex from 'vuex';
import { Auth, Amplify } from 'aws-amplify';
import * as _ from 'lodash';

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        userInfo: null,
        subscription: null,
        accessToken: null,
        refreshToken: null,
        card: null,
    },
    mutations: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        setSubscription(state, subscription) {
            state.subscription = subscription;
        },
        setAccessToken(state, { accessToken, refreshToken }) {
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
        },
        setCurrentCard(state, card) {
            state.card = card;
        },
    },
    getters: {
        jwtToken: (state) => {
            return state.accessToken ? state.accessToken.getJwtToken() : null;
        },
    },
    actions: {
        async updateUserInfo(context) {
            console.log('updateUserInfo-');
            const ui = await Auth.currentUserInfo();
            context.commit('setUserInfo', ui);
            const session = await Auth.currentSession();
            context.commit('setAccessToken', {
                accessToken: session.getAccessToken(),
                refreshToken: session.getRefreshToken(),
            });
            console.log('-updateUserInfo', session);
        },
    },
    modules: {},
});
