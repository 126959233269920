import store from '@/store';
import { Auth, Amplify } from 'aws-amplify';
import axios from 'axios';

let _noLoop_started = false;
async function noLoop(f: () => void) {
    if (_noLoop_started) {
        _noLoop_started = false;
        return;
    } else {
        _noLoop_started = true;
        await f();
        _noLoop_started = false;
    }
}
export async function updateUserInfo() {
    await noLoop(async () => {
        const ui = await Auth.currentUserInfo();
        if (!ui) return;
        store.commit('setUserInfo', ui);
        const session = await Auth.currentSession();
        store.commit('setAccessToken', {
            accessToken: session.getAccessToken(),
            refreshToken: session.getRefreshToken(),
        });
        // this is used by vue-auth-image
        axios.defaults.headers.common['accesstoken'] = store.getters.jwtToken;
    });
}

export async function jwtToken(): Promise<string> {
    // if (!store.getters.jwtToken)
    await updateUserInfo();

    return store.getters.jwtToken;
}
export enum ResponseType {
    json,
    blob,
}
export async function authApi(
    url: string,
    method = 'POST',
    body = undefined,
    responseType = ResponseType.json
) {
    const accesstoken = await jwtToken();
    const result = await fetch(process.env.VUE_APP_SERVER_URL + '/auth/' + url, {
        method,
        // mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json',
            'Response-Type': 'application/json',
            accesstoken: await jwtToken(),
        },
        body: body ? JSON.stringify(body) : undefined,
    });
    switch (responseType) {
        case ResponseType.json:
            return await result.json();
        case ResponseType.blob:
            return await result.blob();
    }
}
