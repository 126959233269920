import { render, staticRenderFns } from "./Wheel.vue?vue&type=template&id=7846ba04&scoped=true&"
import script from "./Wheel.vue?vue&type=script&lang=ts&"
export * from "./Wheel.vue?vue&type=script&lang=ts&"
import style0 from "./Wheel.vue?vue&type=style&index=0&id=7846ba04&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7846ba04",
  null
  
)

export default component.exports