var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-overlay',[_c('v-card',{staticClass:"popupped-card background",attrs:{"min-height":"600px"}},[_c('v-card-text',{staticClass:"popupped-view "},[_c('v-btn',{staticStyle:{"position":"absolute","right":"10px","top":"10px"},attrs:{"enabled":"!!subscribed","icon":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-container',{staticClass:"help-content"},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"10"}},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":require('../assets/subscribe/logo.png')}})]),_c('v-col',{attrs:{"cols":"1"}})],1),_c('v-row',{staticClass:"welcome-text"},[_vm._v(" Welcome to Biominga Wheels of Innovation! Here’s an opportunity to use nature’s time tested strategies and biomimicry for problem solving! With fascinating biological stories this toolkit offers 266 combinations of strategies for solving personal, institutional and global challenges. Learn about nature’s best practices for resilience, flexibility and adaptability and how to accelerate your own innovations! ")]),_c('v-row',{staticStyle:{"margin-top":"20px"}},[_c('v-col',{attrs:{"cols":"5"}}),_c('v-col',{attrs:{"cols":"2"}},[_c('img',{attrs:{"src":require('../assets/subscribe/line.png')}})]),_c('v-col',{attrs:{"cols":"5"}})],1),(_vm.subscribed)?_c('v-row',{},[_c('v-col',{attrs:{"cols":"1"}}),(
                            _vm.subscription.status == 'active' &&
                            !_vm.subscription.cancel_at_period_end
                        )?_c('v-col',{staticClass:"blue-text"},[_vm._v(" Renewal date: "+_vm._s(_vm.toDate(_vm.subscription.current_period_end))+" "),_c('v-btn',{attrs:{"color":"#3e4a62","id":"cancelSubscription"},on:{"click":_vm.cancelSubscription}},[_vm._v(" Cancel renewal ")])],1):_vm._e(),(_vm.subscription.cancel_at_period_end)?_c('v-col',{staticClass:"blue-text"},[_vm._v(" Expiry date: "+_vm._s(_vm.toDate(_vm.subscription.current_period_end))+". (It won't renew automatically.) ")]):_vm._e(),_c('v-col',{attrs:{"cols":"1"}})],1):_vm._e(),_vm._l((_vm.otherSubscriptions),function(sub,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"1"}}),(
                              sub.status == 'active' &&
                              !sub.cancel_at_period_end
                              )?_c('v-col',{staticClass:"blue-text"},[_vm._v(" Renewal date: "+_vm._s(_vm.toDate(sub.current_period_end))+" ")]):_vm._e(),(sub.cancel_at_period_end)?_c('v-col',{staticClass:"blue-text"},[_vm._v(" Expiry date: "+_vm._s(_vm.toDate(sub.current_period_end))+". (It won't renew automatically.) ")]):_vm._e(),_c('v-col',{attrs:{"cols":"1"}})],1)}),(!_vm.subscribed)?_c('v-row',[_c('v-col'),_c('v-col',[_c('v-btn',{attrs:{"id":"checkout","disabled":!_vm.agreed,"color":"#a8e4e2","light":""},on:{"click":_vm.stripeCheckoutSession}},[_vm._v(" Subscribe ")])],1),_c('v-col')],1):_vm._e(),_c('v-row',{staticClass:"small-font"},[_c('v-col',{staticClass:"tos-text"},[_vm._v(" Subscriptions run for 12 months for the cost of $20. At the end of 12 months your subscription will automatically renew unless we hear from you, Subscriptions are non-refundable. Copyright © Nancy Woodman 2021 All rights reserved. Reproduction of the whole or part of the contents without written permission is strictly prohibited."),_c('br'),_vm._v(" The complete documents are available here: "),_c('a',{staticClass:"blue-text",on:{"click":function($event){return _vm.openLink(
                                    'https://www.termsfeed.com/live/29710f14-7078-4d72-9a67-bbac3c495a4c'
                                )}}},[_vm._v(" Terms and conditions ")]),_vm._v(" , "),_c('a',{staticClass:"blue-text",on:{"click":function($event){return _vm.openLink(
                                    'https://www.termsfeed.com/live/184b4ea5-ff73-40e8-a903-fe896fe96ad5'
                                )}}},[_vm._v(" Disclaimer ")]),_vm._v(" , "),_c('a',{staticClass:"blue-text",on:{"click":function($event){return _vm.openLink(
                                    'https://www.termsfeed.com/live/5af9c1e8-2395-4b32-8cdc-ceeca7ebda62'
                                )}}},[_vm._v(" Privacy policy ")]),_vm._v(" . ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',[(!_vm.subscribed)?_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"blue-text"},[_vm._v(" I agree to the "),_c('i',[_vm._v("Terms and conditions")]),_vm._v(" , "),_c('i',[_vm._v("Disclaimer")]),_vm._v(" and "),_c('i',[_vm._v("Privacy policy")]),_vm._v(" . ")])]},proxy:true}],null,false,1805789828),model:{value:(_vm.agreed),callback:function ($$v) {_vm.agreed=$$v},expression:"agreed"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"3"}})],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }